<template>
    <div class="page-rooter-fff">
        <headerBar title="我的战队" left-arrow @click-left="newAppBack"/>
        <div class="page-content" @click="jump('/mini-team/ranking-list')">
            <div class="rank-top row-between-center">
                <div>战队排行</div>
                <van-icon color="#9AA1A9" name="arrow" />
            </div>
        </div>
        <div v-if="teamList.length" class="rank-list">
            <div v-for="(item, index) in teamList" :key="index" class="item" @click="jump('/mini-team/team-detail', {teamId: item.id})">
                <div class="item-top row-start-center">
                    <div class="item-top-left row-center-center">
                        <img :src="item.teamLogo" alt="">
                    </div>
                    <div class="item-top-right">
                        <h4>{{ item.teamName }}</h4>
                        <p class="ellipsis">{{ item.teamProfile }}</p>
                        <div class="row-start-center">
                            <div class="user-list row-start-center">
                                <img v-for="(url, key) in item.userImgList.slice(0, 5)" :key="key" :src="url" alt="">
                            </div>
                            <p>{{ item.teamMembers }}个成员</p>
                        </div>
                    </div>
                </div>
                <div class="item-bottom row-start-center">
                    <div class="item-bottom-left col-center-center">
                        <p :class="[item.teamRank ? 'f40' : 'f22']">{{ item.teamRank ? item.teamRank : '未参与' }}</p>
                        <div>排名</div>
                    </div>
                    <div class="item-bottom-right">
                        <p>战队本月消耗卡路里<span>{{ item.calorieCurrentMonth }}</span>kcal</p>
                        <div class="row-start-center"><img :src="item.userImg" alt="">你本月贡献了{{ item.calorieUser }}kcal</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="rank-list empty col-center-center">
            <img src="https://img.chaolu.com.cn/ACT/mini-team/my-team/empty.png" alt="">
            <p>暂无您的战队<br>预约小班课即可加入战队进行排行</p>
        </div>
    </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import {newAppBack, initBack} from "@/lib/appMethod";
import headerBar from '@/components/app/headBar';
export default {
    data() {
        return {
            teamList: []
        };
    },
    mixins: [userMixin],
    components: {headerBar},
    async created() {
        initBack(); //劫持后退
        await this.$getAllInfo();
        this.param = {userId: this.userId, token: this.token}
        this.getTeamList()
    },
    mounted() {
      window.scrollTo(0, 0)
    },
    methods: {
        newAppBack,
        // 获取战队列表
        getTeamList() {
            this.$axios.post(`${this.baseURLApp}/microTeacherTeamUser/teamListUser`, this.param).then(res => {
                this.teamList = res.data ?? []
                this.teamList.map((item) => {
                  item.userImgList = item.userImgList.reverse()
                })
            })
        },
        jump(path, query = {}) {
            this.$router.push({path, query})
        },
    },
};
</script>

<style lang="less" scoped>
.page-rooter-fff{
    background: #F5F5F5;
    height: 100vh;
    display: flex;
    flex-direction: column;
    .rank-top{
        width: 686px;
        background: #FFFFFF;
        height: 90px;
        margin: 24px auto 12px;
        border-radius: 12px;
        padding: 0 30px;
        box-sizing: border-box;
        div{
            background: url("https://img.chaolu.com.cn/ACT/mini-team/my-team/team_ranking.png") no-repeat left center;
            background-size: 34px 34px;
            padding-left: 54px;
            color: #242831;
            font-size: 28px;
        }
    }
    .rank-list{
        flex-grow: 1;
        padding: 12px 32px 60px;
        overflow-y: auto;
        .item{
            background: #FFFFFF;
            border-radius: 12px;
            padding: 30px 32px;
            & + .item{
                margin-top: 24px;
            }
        }
        .item-top{
            padding-bottom: 32px;
            border-bottom: 2px solid #eee;
            .item-top-left{
                flex-shrink: 0;
                width: 152px;
                height: 152px;
                background: url("https://img.chaolu.com.cn/ACT/mini-team/my-team/border_big.png") no-repeat center center;
                background-size: 100% 100%;
                img{
                    width: 132px;
                    height: 132px;
                    object-fit: cover;
                }
            }
            .item-top-right{
                padding-left: 22px;
                overflow: hidden;
                h4{
                    font-size: 32px;
                    color: #1E212E;
                    line-height: 32px;
                }
                &>p{
                    font-size: 22px;
                    color: #1E212E;
                    line-height: 22px;
                    margin-top: 19px;
                }
                &>div{
                    margin-top: 20px;
                    .user-list{
                        flex-direction: row-reverse;
                        img{
                            width: 36px;
                            height: 36px;
                            border-radius: 50%;
                            object-fit: cover;
                            border: 1px solid #FFFFFF;
                            margin-left: -11px;
                            position: relative;
                            left: 11px;
                        }
                    }
                    p{
                        font-size: 22px;
                        color: #1E212E;
                        margin-left: 21px;
                    }
                }
            }
        }
        .item-bottom{
            padding-top: 32px;
            .item-bottom-left{
                width: 83px;
                height: 83px;
                background: url("https://img.chaolu.com.cn/ACT/mini-team/my-team/bg_ranking.png") no-repeat center center;
                background-size: 100% 100%;
                flex-shrink: 0;
                color: white;
                p{
                    font-style: italic;
                    font-weight: bold;
                    line-height: 40px;
                }
                div{
                    line-height: 18px;
                    font-size: 18px;
                    margin-top: 10px;
                }
            }
            .item-bottom-right{
                color: #1E212E;
                padding-left: 19px;
                p{
                    font-size: 22px;
                    span{
                        padding: 0 6px;
                        font-weight: bold;
                        font-size: 32px;
                    }
                }
                div{
                    margin-top: 12px;
                    font-size: 22px;
                    img{
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        margin-right: 9px;
                        object-fit: cover;
                    }
                }
            }
        }
    }
    .empty{
        img{
            width: 320px;
            height: 320px;
        }
        p{
            margin-top: 21px;
            text-align: center;
            line-height: 44px;
            color: #6C727A;
            font-size: 28px;
        }
    }
}
</style>
